<template>
  <div class="search">
    <el-form :model="form" class="demo-form-inline" size="small" inline>
      <div class="top">
        <el-form-item label="提现状态：" class="form-item" prop="state">
          <el-select v-model="form.state" placeholder="请选择" class="form-item-select">
            <el-option label="全部" value=" "></el-option>
            <el-option label="待审核" :value="1"></el-option>
            <el-option label="待打款" :value="2"></el-option>
            <el-option label="已打款" :value="3"></el-option>
            <el-option label="打款失败" :value="6"></el-option>
            <el-option label="已驳回" :value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="供应商ID：" class="form-item">
          <el-input v-model="form.storeId" placeholder="请输入" class="form-item-input"></el-input>
        </el-form-item>
        <el-form-item label="供应商名称：" class="form-item">
          <el-input
            v-model="form.storeName"
            placeholder="请输入"
            class="form-item-input"
          ></el-input>
        </el-form-item>
        <el-form-item class="form-item" label-width="30px">
          <el-link type="primary" style="margin-right: 10px" @click="checkFold"
            >{{ fold ? '收起' : '展开' }}
          </el-link>
          <el-button icon="el-icon-search" type="primary" size="small" @click="search"
            >查询</el-button
          >
          <el-button icon="el-icon-refresh-left" size="small" @click="reset">重置</el-button>
        </el-form-item>
      </div>

      <div v-show="fold" class="more">
        <el-form-item label="提现申请编号：" class="form-item">
          <el-input
            v-model="form.withdrawNo"
            placeholder="请输入"
            class="form-item-input"
          ></el-input>
        </el-form-item>
        <el-form-item label="审核人：" class="form-item">
          <el-input
            v-model="form.auditUserName"
            placeholder="请输入"
            class="form-item-input"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="确认打款人：" class="form-item">
          <el-input v-model="form.state" placeholder="请输入" class="form-item-input"></el-input>
        </el-form-item> -->
      </div>
      <div v-show="fold" class="more">
        <el-form-item label="申请时间：">
          <el-date-picker
            v-model="form.time1"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            style="width: 340px"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="审核时间：">
          <el-date-picker
            v-model="form.time2"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            style="width: 340px"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="打款时间：">
          <el-date-picker
            v-model="form.time3"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            style="width: 340px"
          >
          </el-date-picker>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'PageHeader',
  components: {},
  data() {
    return {
      form: { storeId: '', state: '' },
      fold: false,
      tags: [{ name: '标签一' }, { name: '标签二' }]
    };
  },
  mounted() {
    const data = this.$route.query;
    if (data) {
      this.form.storeId = data.storeId;
      this.form.state = data.state ? Number(data.state) : '';
    }
  },
  methods: {
    getTime(name, e) {
      return moment(this.form[name][e]).format('YYYY-MM-DD HH:mm:ss');
    },
    search() {
      const data = { ...this.form };

      if (data.time1) {
        data.beginApplyTime = this.getTime('time1', 0);
        data.endApplyTime = this.getTime('time1', 1);
      }
      if (data.time2) {
        data.beginAuditTime = this.getTime('time2', 0);
        data.endAuditTime = this.getTime('time2', 1);
      }
      if (data.time3) {
        data.beginPayerTime = this.getTime('time3', 0);
        data.endPayerTime = this.getTime('time3', 1);
      }
      delete data.time1;
      delete data.time2;
      delete data.time3;
      this.$parent.handleSearch(data);
    },
    reset() {
      this.form = {};
      this.$parent.handleRest(this.form);
    },
    checkFold() {
      this.fold = !this.fold;
    }
  }
};
</script>
<style lang="less" scoped>
@import './search.less';
</style>
