<template>
  <el-dialog :close-on-click-modal="false" width="600px" :title="title" :visible.sync="dialogFormVisible" @close="cancel">
    <div v-loading="loading">
      <el-form v-if="status === 1" ref="ruleForm" :model="ruleForm" label-width="80px" :rules="rules">
        <div>
          <el-form-item label="开票凭证：" prop="billUrls">
            <UploadImg :bill-urls.sync="ruleForm.billUrls" />
          </el-form-item>
          <el-form-item label="备注：">
            <el-input v-model="ruleForm.remark" type="textarea" placeholder="请输入备注" maxlength="300" :rows="5" show-word-limit></el-input>
          </el-form-item>
          <div style="color: #f59a23">* 请先确认收到供应商提供发票后录入发票凭证再通过。</div>
        </div>
      </el-form>
      <el-form v-if="status === 2" ref="ruleForm" :model="ruleForm" :rules="rules">
        <el-form-item prop="remark" label="驳回原因">
          <el-input v-model="ruleForm.remark" type="textarea" placeholder="请输入内容" maxlength="300" :rows="5" show-word-limit></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button size="small" @click="cancel">取 消</el-button>
      <el-button size="small" type="primary" @click="ok">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { audit } from '@/api/pay'
import UploadImg from '../uploadImg/index.vue'
export default {
  components: { UploadImg },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    status: {
      type: Number,
      default: null,
    },
    row: {
      type: [Object, String],
      default: () => {},
    },
    selectRecords: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      ruleForm: {
        reason: '',
        billUrls: [],
      },
      dialogFormVisible: false,
      rules: {
        remark: [{ required: true, message: '请输入驳回原因', trigger: 'blur' }],
        billUrls: [{ required: true, message: '请上传开票凭证', trigger: 'blur' }],
      },
      loading: false,
      action: '',
    }
  },
  watch: {
    visible(val) {
      this.dialogFormVisible = val
    },
  },
  mounted() {
    this.action = process.env.VUE_APP_BASE_URL + 'misc-web-api/common/file/uploadImage'
  },
  methods: {
    cancel() {
      this.$refs.ruleForm.resetFields()
      this.ruleForm = { reason: '', billUrls: [] }
      this.loading = false
      this.$emit('cancel')
    },
    ok() {
      console.log(this.ruleForm.billUrls)
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const ids = []
          if (this.row === '批量') {
            this.selectRecords.forEach((e) => {
              ids.push(e.withdrawId)
            })
          }
          const data = {
            withdrawId: ids.length > 0 ? ids.join(',') : this.row.withdrawId,
            storeId: this.row.storeId,
            remark: this.ruleForm.remark,
            billUrls: this.ruleForm.billUrls,
            state: this.status,
          }
          this.$confirm(`是否确认${this.status === 1 ? '通过' : '驳回'}？`)
            .then(() => {
              this.loading = true
              audit({ data })
                .then((res) => {
                  this.loading = false
                  if (res) {
                    this.$emit('getTable')
                    this.$message({
                      message: '操作成功',
                      type: 'success',
                    })
                  }
                })
                .catch(() => {
                  this.loading = false
                })
            })
            .catch(() => {})
        } else {
          console.log('error submit!!')
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.form {
  ::v-deep.el-form-item__label {
    margin-right: 10px;
  }
}
</style>
