<template>
  <el-upload :action="action" list-type="picture-card" :on-success="handleSuccess" :on-remove="handleRemove" :file-list="fileList">
    <div class="text">
      <i class="el-icon-plus"></i>
      <span>上传图片</span>
      <span>最多五张</span>
    </div>
  </el-upload>
</template>

<script>
export default {
  props: {
    billUrls: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      action: process.env.VUE_APP_BASE_URL + 'misc-web-api/common/file/uploadImage',
      fileList: [],
    }
  },
  watch: {
    billUrls(val) {
      if (val.length === 0) {
        this.fileList = []
      }
    },
    fileList(val) {
      if (val.length > 4) {
        const dom = document.querySelector('.el-upload--picture-card')
        dom.style.display = 'none'
      } else {
        const dom = document.querySelector('.el-upload--picture-card')
        setTimeout(() => {
          dom.style.display = 'inline-block'
        }, 1100)
      }
    },
  },
  methods: {
    handleSuccess(response, file, fileList) {
      this.fileList = fileList
      const arr = []
      this.fileList.forEach((e) => {
        if (e.response) {
          arr.push(e.response.data.http)
        }
      })
      this.$emit('update:billUrls', arr)
    },
    handleRemove(file, fileList) {
      this.fileList = fileList
      const arr = []
      this.fileList.forEach((e) => {
        arr.push(e.response.data.http)
      })
      this.$emit('update:billUrls', arr)
    },
  },
}
</script>

<style lang="less" scoped>
.text {
  display: flex;
  flex-direction: column;
  width: 148px;
  height: 148px;
  align-items: center;
  justify-content: center;
  span {
    display: inline;
    line-height: 20px;
    font-size: 14px;
    color: #999;
  }
}
</style>
