<template>
  <section class="withdrawal-application-wrapper">
    <search ref="search" @search="handleSearch" @reset="handleRest" />
    <div class="withdrawal-application-table">
      <vxe-grid ref="xTable" v-loading="loading" border :data="dataSource" :columns="tableColumn" size="small" v-bind="tableOptions">
        <template #amount="{ row }">
          <span class="moneyColor">¥{{ row.amount }}</span>
        </template>
        <template #toolbar_buttons>
          <!-- <el-button size="small" type="default">导出</el-button> -->
          <!-- <el-button @click="pass('批量', 1)" size="small" type="default">通过</el-button>
          <el-button @click="pass('批量', 2)" size="small" type="default">驳回</el-button> -->
        </template>
        <template #stateName="{ row }"
          ><span :style="{ color: getStatusColor(row.state) }">
            {{ row.state == 1 ? '待审核' : row.state == 4 ? '已驳回' : row.state == 3 ? '已打款' : row.stateName }}
          </span>
        </template>
        <template #auditUserName="{ row }"
          ><span>
            {{ row.auditUserName ? row.auditUserName : '--' }}
          </span>
        </template>
        <template #payerUserName="{ row }"
          ><span>
            {{ row.payerUserName ? row.payerUserName : '--' }}
          </span>
        </template>
        <template #confimPayTime="{ row }"
          ><span>
            {{ row.confimPayTime ? row.confimPayTime : '--' }}
          </span>
        </template>
        <template #auditTime="{ row }"
          ><span>
            {{ row.auditTime ? row.auditTime : '--' }}
          </span>
        </template>
        <template #operate="{ row }">
          <el-button v-if="powerList.find((e) => e.nodeCode == 'YY_PAYCENTER_WITHDRAWDETAIL')" type="text" @click="detail(row)">详情</el-button>
          <el-button v-if="row.state === 1 && powerList.find((e) => e.nodeCode == 'YY_PAYCENTER_AGREEWITHDRAW')" type="text" @click="pass(row, 1)">通过</el-button>
          <el-button v-if="row.state === 1 && powerList.find((e) => e.nodeCode == 'YY_PAYCENTER_REFUSEWITHDRAW')" type="text" @click="pass(row, 2)">驳回</el-button>
          <!-- <el-button v-if="row.state === 2" @click="pass(row, 3)" type="text">确认打款</el-button> -->
        </template>
        <template #pager>
          <i-pagination
            :page-size="iPagination.pageSize"
            :current-page="iPagination.currentPage"
            :page-sizes="[10, 20, 30, 40]"
            layout="total,prev, pager, next ,sizes,jumper"
            :total="iPagination.total"
            @current-change="handlePageChange"
            @size-change="handleSizeChange"
          />
        </template>
      </vxe-grid>
    </div>
    <WithdrawalModals
      :status="status"
      :visible="dialogFormVisible"
      :title="title"
      :row="rowData"
      :select-records="selectRecords"
      @cancel="dialogFormVisible = false"
      @getTable="search"
    />
  </section>
</template>

<script>
import WithdrawalModals from '@/components/withdrawalModals/index.vue'
import Search from './components/search/index.vue'
import IPagination from '@/components/i-pagination/index.vue'
import TableListMixin from '@/minixs/TableListMinix'

export default {
  name: 'WithDrawalApplication',
  components: {
    Search,
    IPagination,
    WithdrawalModals,
  },
  mixins: [TableListMixin],
  data() {
    return {
      url: {
        list: '/supplier-web-api/admin/withdraw/getList',
      },
      tableColumn: [
        // { type: 'checkbox', width: 50 },
        { field: 'withdrawNo', title: '提现申请编号', minWidth: 200 },
        { field: 'storeId', title: '供应商ID', minWidth: 100 },
        { field: 'storeName', title: '供应商名称', minWidth: 150 },
        { field: 'amount', title: '申请金额', slots: { default: 'amount' }, minWidth: 120 },
        { field: 'applyDate', title: '申请时间', minWidth: 150 },
        {
          field: 'auditUserName',
          title: '审核人',
          slots: { default: 'auditUserName' },
          minWidth: 120,
        },
        { field: 'auditTime', title: '审核时间 ', slots: { default: 'auditTime' }, minWidth: 150 },
        // {
        //   field: 'payerUserName',
        //   title: '确认打款人',
        //   slots: { default: 'payerUserName' },
        //   minWidth: 150
        // },
        { field: 'amount', title: '打款金额', slots: { default: 'amount' }, minWidth: 120 },
        {
          field: 'payerTime',
          title: '打款时间 ',
          minWidth: 150,
        },
        { field: 'stateName', title: '提现状态', slots: { default: 'stateName' }, minWidth: 100 },
        { title: '操作', slots: { default: 'operate' }, width: 130 },
      ],
      tableData: [],
      tableOptions: {
        toolbarConfig: {
          slots: {
            // 自定义工具栏模板
            buttons: 'toolbar_buttons',
          },
        },
      },
      title: '',
      dialogFormVisible: false,
      status: 0,
      selectRecords: [],
      rowData: {},
    }
  },
  computed: {
    powerList: function () {
      return this.$store.getters.getPowerList
    },
  },
  methods: {
    getStatusColor(e) {
      switch (e) {
        case 1:
          return '#02a7f0'
        case 2:
          return '#8080ff'
        case 3:
          return '#70b603'
        default:
          return ''
      }
    },
    search() {
      this.dialogFormVisible = false
      this.$refs.search.search()
    },
    detail(row) {
      this.$router.push({
        path: '/withdrawal/detail',
        query: { id: row.withdrawId },
      })
    },
    // 通过
    pass(row, state) {
      switch (state) {
        case 1:
          this.title = '通过'
          break
        case 2:
          this.title = '驳回'
          break
        case 3:
          this.title = '确认打款'
          break
        default:
          this.title = ''
          break
      }
      this.rowData = row
      this.dialogFormVisible = true
      this.status = state
      if (row === '批量') {
        this.selectRecords = this.$refs.xTable.getCheckboxRecords()
        console.log(this.selectRecords)
      }
    },
  },
}
</script>

<style lang="less" scoped>
.withdrawal-application-wrapper {
  margin-top: 16px;
  .withdrawal-application-table {
    background: #fff;
    margin-top: 20px;
    padding: 0 20px;
  }
}
</style>
